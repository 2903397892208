<template>
  <div class="detador">
    <div class="detador-top">
      <TitleBox :titlename="titlename" />
      <div class="closebox" @click="cancel()">
        <i class="el-icon-close"></i>
      </div>
    </div>
    <div class="detador-center">
      <video id="videoElement" :src="videoUrlpm4" controls autoplay muted
        v-if="videoUrlpm4"></video>
      <easy-player v-else ref="videoplay" :video-url="vodeoUrl" :hasAudio="false"
        :reconnection="true">
      </easy-player>

    </div>
  </div>
</template>

<script>
// import flvjs from 'flv.js'
import EasyPlayer from '@easydarwin/easyplayer'

import TitleBox from '../visualization/components/title/index.vue'
export default {
  props: ['detailsshow', 'dronename', 'snC0de', 'dronevideoUrl'],
  components: {
    TitleBox,
    EasyPlayer
  },
  data () {
    return {
      isshow: false,
      titlename: '29°57′41.80″N',

      flvPlayer: null,
      inputvalue: '',
      devicename: '60',
      vodeoUrl: '',
      list: [],
      flvPlayerList: [],
      thisContext: null,
      imgs: null,
      file: null,
      thisVideo: null,
      thisCancas: null,
      videoWidth: null,
      videoHeight: null,

      recordedBlobs: [],
      fileName: '',
      status: false,
      stream: null,
      options: {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 4500000,
        mimeType: 'video/webm'
      },
      startTime: 0,
      timeLong: 0,
      timeLongs: null,
      endTime: 0,
      serialNumber: 'CGD4N1DX20B000033',
      videoUrlpm4: ''
    }
  },
  computed: {},
  watch: {
    dronename: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.titlename = newvalue
          // console.log(newvalue, 'zizujianop')
        })
      }
    },
    snC0de: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.serialNumber = newvalue
          // console.log(this.serialNumber, 'this.serialNumber')
          this.vodeoUrl =
            // 'ws://220.202.12.111:8866/live?url=rtsp://admin:txhy12345@10.18.100.139/media/video2/multicast.flv'
            'http://183.94.86.130:18000/flv/hls/app_' + this.serialNumber + '.flv'
            // 'http://36.133.230.141:8888/uavDms/app_' + this.serialNumber + '.flv'
          console.log(this.vodeoUrl, 'newvalue')
          // console.log(this.serialNumber, 'this.serialNumber')
          // console.log(newvalue, 'zizujianop')
        })
      }
    },
    dronevideoUrl: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.videoUrlpm4 = newvalue
        })
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    mouseover () {
      this.isshow = true
    },
    mouseout () {
      this.isshow = false
    }

  },
  created () {
    // this.clickhandleitem()
  },
  mounted () {
    // this.clickhandleitem()
  },
  beforeDestroy () {
    // console.log('销毁')
    // this.destoryVideo(this.flvPlayer)
  }
}
</script>
<style lang="less" scoped>
.detador {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // position: relative;
  background: RGBA(10, 34, 55, 1);
  border: 2px solid #59a5ff;
  // display: flex;
  z-index: 3;
  &-top {
    width: 100%;
    height: 10%;
    margin-left: 1%;
    display: flex;
    .closebox {
      position: absolute;
      right: 0;
      top: 0%;
      width: 30px;
      height: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      text-align: center;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
  &-center {
    width: 100%;
    height: 90%;
    color: #ffffff;
    position: relative;
    .img1 {
      width: 100%;
      height: 100%;
    }
    .img2 {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 134px;
      right: 55px;
    }
    .imgbox {
      width: 112px;
      height: 300px;
      position: absolute;
      top: 49px;
      right: 15px;
      .img3 {
        width: 112px;
        height: 153px;
      }
      .imgjt {
        width: 42px;
        height: 42px;
        margin-top: 10px;
        margin-left: 35px;
      }

      .imglp {
        width: 42px;
        height: 42px;
        margin-top: 15px;
        margin-left: 35px;
      }
    }

    video {
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    canvas {
      width: 70%;
      height: 110%;
    }
  }
}
</style>
